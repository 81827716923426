import * as React from 'react';
import { Trans } from 'react-i18next';
import Icon from '../icon';
import Tooltip from '../tooltip';

import type { IconTypes } from '../icon';

export type Props = {
  children: any,
};

export type CardProps = {
  title: any,
  description?: any,
  count?: number,
  action?: any,
  icon?: IconTypes,
  children?: any,
};

export type StatisticsCardProps = {
  icon?: IconTypes,
  title: string,
  color?: string,
  count?: number,
  action?: any,
  oldCount?: number,
  percentage?: number,
  description?: string | JSX.Element | JSX.Element[],
  tooltip?: string | JSX.Element,
  formatter?: (count: number) => any,
};

const OverviewContentProgress = ({ children }: Props) => (
  <div className="Overview__Content__Progress">
    {children}
  </div>
);

OverviewContentProgress.Card = ({
  title, description, count, action, icon, children,
}: CardProps) => (
  <div className="Overview__Content__Progress__Card">
    {icon && <div className="Overview__Content__Progress__Card__Icon"><Icon type={icon} /></div>}
    <div className="Overview__Content__Progress__Card__Title">{title}</div>
    {description && <div className="Overview__Content__Progress__Card__Description">{description}</div>}
    {children}
    {(count !== undefined || action) && (
      <div className="Overview__Content__Progress__Card__Footer">
        {count !== undefined && (
          <div className="Overview__Content__Progress__Card__Count">
            <Trans i18nKey="common:overview_progress_employees" values={{ count }} components={[<small>a</small>]} />
          </div>
        )}
        {action && <div className="Overview__Content__Progress__Card__Action">{action}</div>}
      </div>
    )}
  </div>
);

const getIncreaseStatus = (increase: number) => {
  if (increase > 0) return 'positive';
  if (increase < 0) return 'negative';

  return 'default';
};

OverviewContentProgress.StatisticsCard = ({
  icon = 'group__filled',
  color,
  title,
  description,
  action,
  count,
  oldCount,
  tooltip,
  formatter,
}: StatisticsCardProps) => {
  let increase;

  if (count !== undefined && oldCount) {
    increase = (count > oldCount ? (count / oldCount) * 100 - 100 : (100 - 100 / (oldCount / count)) * -1);
  }

  return (
    <div className="Overview__Content__StatisticsCard">
      <div className="Overview__Content__StatisticsCard__Title">
        {icon && <Icon type={icon} style={{ backgroundColor: color }} />}
        {title}
        {tooltip &&
          <Tooltip placement="bottom" title={tooltip}>
            <Icon type="help" className="Overview__Content__StatisticsCard__Tooltip" />
          </Tooltip>}
      </div>
      <div className="Overview__Content__StatisticsCard__Count">
        {count && formatter ? formatter(count) : count!.toLocaleString()}
        {increase !== undefined && (
          <span className={`Overview__Content__StatisticsCard__Count__Increase
            Overview__Content__StatisticsCard__Count__Increase--status-${getIncreaseStatus(increase)}`}
          >
            {increase !== 0 && <Icon type={increase > 0 ? 'arrow_drop_up' : 'arrow_drop_down'} />}
            {/* @ts-expect-error */}
            {`${Math.abs(increase.toFixed(1))}%`}
          </span>
        )}
        {action}
      </div>
      {description && (
        <small className="Overview__Content__StatisticsCard__Description">
          {description}
        </small>
      )}
    </div>
  );
};

OverviewContentProgress.ArrowDivider = () => (
  <div className="Overview__Content__Progress__Divider">
    <Icon type="arrow_forward" />
  </div>
);

OverviewContentProgress.Divider = () => (
  <div className="Overview__Content__Progress__Divider" />
);

export default OverviewContentProgress;
