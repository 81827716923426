import React, { ComponentProps } from 'react';
import Dropdown from '@common/components/dropdown';
import { Button } from '@common/components/button';

type Props = Omit<ComponentProps<typeof Dropdown>, 'toggle' | 'alignRight'>;

export const TableDropdown = ({ children, disabled, ...props }: Props) => (
  <Dropdown
    {...props}
    alignRight
    disalbed={disabled}
    toggle={(
      <Button
        icon="more_vert"
        className="Table__DropdownTrigger"
        disabled={!!props.disabled}
      />
    )}
  >
    {children}
  </Dropdown>
);

TableDropdown.Item = Dropdown.Item;
