import React from 'react';
import { Trans } from 'react-i18next';

type Props = {
  children: any;
};

export const OverviewHeader = ({ children }: Props) => (
  <div className="Overview__Header">{children}</div>
);

type TitleProps = {
  children: any;
  learnMoreHref?: string;
};

OverviewHeader.Title = ({ children, learnMoreHref }: TitleProps) => (
  <div className="Overview__Header__Title">
    {children}
    {learnMoreHref && <a href={learnMoreHref} target="_blank" rel="noreferrer"><Trans i18nKey="core:learn_more" /></a>}
  </div>
);

type FilterProps = {
  options: {
    key: string;
    label: string;
    count?: number;
  }[];
  onChange: (key: string) => void;
  active?: string | null;
};

OverviewHeader.Filter = ({ options, onChange, active }: FilterProps) => (
  <div className="Overview__Header__Filter">
    {options.filter((option) => !!option).map((option) => (
      <div
        role="button"
        key={option.key}
        className={`Overview__Header__Filter__Option${option.key === active ? ' Overview__Header__Filter__Option--active' : ''}`}
        onClick={option.key !== active ? () => onChange(option.key) : undefined}
      >
        {option.label}
        {' '}
        {option.count !== undefined && <span>{option.count}</span>}
        {option.key === active && (
          <svg width="24" height="12" className="Overview__Header__Filter__Active">
            <polygon points="0,12 12,0 24,12" />
            <polygon points="1.5,12 12,1.5 22.5,12" />
          </svg>
        )}
      </div>
    ))}
  </div>
);
