// this was created as a quick fix for PD-8496
// see the comment by kodedninja here https://github.com/react-dnd/react-dnd/issues/3119
// the guy says that he does not know whether that solution is OK
// so we should implement what the guy in the original post says: a DndProvider
// at the root of the React tree

import { HTML5Backend } from 'react-dnd-html5-backend';
import { createDragDropManager } from 'dnd-core';

const dndManager = createDragDropManager(HTML5Backend);

export default dndManager;
