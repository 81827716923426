import React from 'react';
import { combineClassNames } from '@common/utils/combineClassNames';
import { OverviewHeader } from './header';
import OldRow from './row';

type BaseProps = {
  children: React.ReactNode;
};

export const Overview = ({ children, fill, id }: BaseProps & { fill?: boolean; id?: string; }) => (
  <div id={id} className={fill ? 'Overview Overview--fill' : 'Overview'}>
    {children}
  </div>
);

export const OverviewContent = ({ children, flex, className }: BaseProps & { flex?: boolean, className?: string }) => (
  <div
    className={combineClassNames('Overview__Content', className, {
      'Overview__Content--flex': flex,
    })}
  >
    {children}
  </div>
);

OverviewContent.Row = OldRow;

export const OverviewSection = ({ children }: BaseProps) => (
  <div className="OverviewSection">{children}</div>
);

export const Row = ({ children }: BaseProps) => (
  <div className="OverviewRow">{children}</div>
);

Overview.Header = OverviewHeader;
Overview.Content = OverviewContent;
Overview.Row = Row;

Overview.Status = ({ children }: BaseProps) => (
  <div className="Overview__Status">
    {children}
  </div>
);

// @ts-ignore
Overview.Status.Bar = ({ children, active }: BaseProps & { active?: boolean }) => (
  <div className={`Overview__Status__Bar${active ? ' Overview__Status__Bar--active' : ''}`}>
    {children}
  </div>
);

Overview.Placeholder = ({ children }: BaseProps) => (
  <div className="Overview__Placeholder">{children}</div>
);

export default Overview;
